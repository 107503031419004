import { useDecision } from '@optimizely/react-sdk';
import { useMemo } from 'react';

type TavernNationalLaunchVariables = {
  new_recipes_badge: { recipes: { ids: string[] } };
};

const useShowNewBadge = (productId?: string): { shouldShowNewBadge: boolean } => {
  // TODO: Refactor this to pull from a CMS one day for new badges on products
  const [{
    enabled: tavernDecisionEnabled,
    variables: tavernDecisionVars
  }] = useDecision('cb-tavern_national_launch', { autoUpdate: true });

  const decisionNewRecipesBadge = (tavernDecisionVars as TavernNationalLaunchVariables)?.new_recipes_badge;

  const shouldShowNewBadge = useMemo(() => {
    const newRecipesIdList = decisionNewRecipesBadge?.recipes?.ids ?? [];
    // Doing it this way so any product id substring match will work. So we can add many Ids (Yum/QO/Some future system)
    return tavernDecisionEnabled && !!productId && newRecipesIdList.some((recipe) => productId.includes(recipe));
  }, [decisionNewRecipesBadge?.recipes?.ids, tavernDecisionEnabled, productId]);

  return { shouldShowNewBadge };
};

export default useShowNewBadge;
