import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import dataAnalytics from '@/dataAnalytics';
import { largeStartBreakpoint, mobileStartBreakpoint, smallMobileStartBreakpoint } from '../../materialUi/theme';
import { openLocalizationRail } from '../actions';
import MapPinIcon from '../icons/MapPinIcon';
import { onRibbonInitialLoad, onRibbonClick } from '../../dataAnalytics/dataAnalyticsHelper';
import fontStyles from '../../common/fontStyles';
import { NATIONAL } from '../constants';
import colors from '../../common/colors';
import telemetry from '../../telemetry';
import { Device, HiddenOnDevice } from '../../common/ResponsiveContext';
import { RootState } from '@/rootStateTypes';
import { AuthState } from '@/auth/userStates';
import { selectors } from '@/salesExperience/salesExperience.slice';
import { WEB1 } from '@/salesExperience/constants';
import { Paths } from '@/router/nextJsRouter';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { useDecision } from '@optimizely/react-sdk';
import useTrackedDecision from '@/dataAnalytics/hooks/useTrackedDecision';
import useWindowSize, { isMobile } from '@/common/useWindowSize';
import Routes from '@/router/routes';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...fontStyles.commonComponentSelectedState,
    fontFamily: 'open_sans_semi',
    margin: '32px 0px',
    height: '72px',
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      border: '1px solid #e52a33'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      margin: '43px 0px 46px'
    },
    '&:focus': {
      padding: '0px'
    }
  },
  widgetRoot: {
    height: '100%',
    justifyContent: 'space-between'
  },
  mapPinIcon: {
    display: 'flex',
    padding: '0 16px 0 12px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingLeft: '0'
    },
    [theme.breakpoints.down('xl')]: {
      paddingRight: '8px'
    }
  },
  explanationRoot: {
    height: '100%',
    flexWrap: 'nowrap',
    paddingRight: '5px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      border: '0',
      borderRight: `1px solid ${colors.gray400}`,
      justifyContent: 'center'
    }
  },
  callToAction: {
    ...fontStyles.h2,
    fontWeight: 800,
    flex: '1.1',
    marginTop: '5px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '36px'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.h2Mobile,
      lineHeight: '25px'
    },
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      flex: 'none'
    }
  },
  mobileActionDescription: {
    fontSize: '14px'
  },
  actionDescription: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '21px',
    padding: '0 10px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '19px'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      display: 'none'
    }
  },
  findDealsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      height: '100%'
    }
  },
  findDealsButton: {
    height: '40px',
    borderRadius: '8px',
    backgroundColor: colors.red,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 22px 0 6px',
    color: colors.white,
    fontSize: '16px'
  },
  mobileFindDealsButton: {
    height: '100%',
    backgroundColor: '#e52a33',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white
  }
}));

interface LocalizationRibbonProps {
  openLocalizationRail: ({ routeTo }: { routeTo: string }) => void;
  isLocalizedOrLoadingLocalization: boolean;
  loginStatus: AuthState
}

function LocalizationRibbon({
  openLocalizationRail, isLocalizedOrLoadingLocalization, loginStatus
}: LocalizationRibbonProps) {
  const classes = useStyles();
  const analytics = useAnalytics();
  const experience = useSelector(selectors.experience);
  const isEmbedded = process.env.REACT_APP_EMBEDDED === 'true';
  const [localizationRibbonDecision] = useTrackedDecision(
    'exp-abt-8-local-deals-home-page-banner', { optimizelyOptions: { autoUpdate: true }}
  );
  let ribbonTitle = 'Start here';
  let ribbonDescription = 'Find your store to see local deals';
  let ribbonButtonText = 'FIND DEALS';
  let ribbonTitleFont = '';
  let ribbonDescriptionFont = '';
  const router = useRouter();

  const windowSize = useWindowSize();
  const mobileDefault = isMobile(windowSize);
  const sendDataAnalytics = () => {
    if (isEmbedded) {
      dataAnalytics.pushEventAnalytics(onRibbonInitialLoad());
    } else {
      analytics.push(() => onRibbonInitialLoad());
    }
  };

  useEffect(() => {
    const authenticatedOrEmbeddedUserIsNotLocalized = !isLocalizedOrLoadingLocalization
      && (isEmbedded || loginStatus !== AuthState.UNKNOWN);

    if (authenticatedOrEmbeddedUserIsNotLocalized) {
      sendDataAnalytics();
      telemetry.addCustomEvent('web2-localization-ribbon-impression');
    }
  }, [isLocalizedOrLoadingLocalization, loginStatus]);

  const handleOnRibbonClick = () => {
    const dealsRoute = experience === WEB1 ? Paths.DEALS : Paths.DEALSW2;
    analytics.push(() => onRibbonClick());
    if(isLocalizedOrLoadingLocalization) {
      router.push({ pathname: Routes.DEALS });
      return;
    }
    openLocalizationRail({ routeTo: dealsRoute });
  };

  const handleOnKeyDown = (event: any) => {
    if (event.key === 'Enter') handleOnRibbonClick();
  };

  if (isLocalizedOrLoadingLocalization && !localizationRibbonDecision?.enabled) {
    return <></>;
  }

  if (isLocalizedOrLoadingLocalization && localizationRibbonDecision?.enabled &&
    localizationRibbonDecision?.variables) {
    ribbonTitle = localizationRibbonDecision.variables.title as string;
    ribbonDescription = localizationRibbonDecision.variables.description as string;
    ribbonButtonText = localizationRibbonDecision.variables.cta_text as string;
    ribbonTitleFont = mobileDefault ? '' : localizationRibbonDecision.variables.title_font_size as string;
    ribbonDescriptionFont =  localizationRibbonDecision.variables.ribbon_description_font as string;
  }

  return (
    <Card tabIndex={0} role="button" data-testid="LocalizationRibbon" variant="outlined" className={classes.root}
      onClick={handleOnRibbonClick} onKeyDown={handleOnKeyDown} aria-label="Find your local store" aria-haspopup>
      <Grid container spacing={0} direction="row" className={classes.widgetRoot} alignItems="center">
        <Grid item container data-testid="explanation" className={classes.explanationRoot} alignItems="center" xs={11} md={3}>
          <div className={classes.mapPinIcon}>
            <MapPinIcon />
          </div>
          <div>
            <Typography className={classes.callToAction} style={{fontSize : ribbonTitleFont}}>
              {ribbonTitle}
            </Typography>
            <HiddenOnDevice {...Device.DESKTOP}>
              <div className={classes.mobileActionDescription} style={{fontSize : ribbonDescriptionFont}}>
                {ribbonDescription}
              </div>
            </HiddenOnDevice>
          </div>
        </Grid>
        <Grid item container md={5} lg={6} className={classes.actionDescription}>
          {ribbonDescription}
        </Grid>
        <Grid item xs={1} md={4} lg={3} data-testid="findDeals" className={classes.findDealsContainer}>
        <HiddenOnDevice {...Device.MOBILE}>
            <div className={classes.findDealsButton}>
              {ribbonButtonText}
            </div>
          </HiddenOnDevice>
          <HiddenOnDevice {...Device.DESKTOP}>
            <div data-testid="mobileFindDeals" className={classes.mobileFindDealsButton}>
              <ArrowForwardIosIcon />
            </div>
          </HiddenOnDevice>
        </Grid>
      </Grid>
    </Card>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLocalizedOrLoadingLocalization: state.domain.localization.localizedStore !== NATIONAL,
  loginStatus: state.domain.user.loginStatus
});

const mapDispatchToProps = {
  openLocalizationRail
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationRibbon);
