import { useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

export const AATestRuleKey = 'dtg-994_aa_test_rule';
export const AATestVariationA = 'dtg-994-variation-a';
export const AATestVariationB = 'dtg-994-variation-b';

const BASE_AA_TEST_EVENT = {
  event: 'FFE-AATest',
  event_category: 'Feature Flag Experiment'
};

export function useHomepageAnalytics() {
  const analytics = useAnalytics();

  const [{ enabled: oneClickPopularPizzaTracking }] = useDecision('dtg-425-one-click-popular-pizza-tracking');
  const [{ enabled: oneClickDecisionEnabled }] = useDecision('cb-one_click');
  const [{ enabled: aaTestEnabled, ruleKey: aaTestRuleKey, variationKey: aaTestVariationKey }] = useDecision('dtg-994-aa-test');

  useEffect(() => {
    if (!aaTestEnabled || aaTestRuleKey !== AATestRuleKey) {
      return;
    }
    switch (aaTestVariationKey) {
      case AATestVariationA:
        analytics.push(() => ({
          ...BASE_AA_TEST_EVENT,
          beacon_id: '99.04.02',
          event_action: 'Untreated_DTG_AAExperiment'
        }));
        break;
      case AATestVariationB:
        analytics.push(() => ({
          ...BASE_AA_TEST_EVENT,
          beacon_id: '99.03.02',
          event_action: 'Treated_DTG_AAExperiment'
        }));
        break;
      default:
    }
  }, [analytics, aaTestEnabled, aaTestRuleKey, aaTestVariationKey]);

  useEffect(() => {
    if (!oneClickPopularPizzaTracking) return;
    if (oneClickDecisionEnabled) {
      // HomePPOneClick Treated
      analytics.push(() => ({
        beacon_id: '99.03.02',
        event: 'FFE-HomePopularPizzaOneClick',
        event_category: 'Feature Flag Experiment',
        event_action: 'Treated_HomePopularPizzaOneClick'
      }));
    } else {
      // HomePPOneClick Not Treated
      analytics.push(() => ({
        beacon_id: '99.04.02',
        event: 'FFE-HomePopularPizzaOneClick',
        event_category: 'Feature Flag Experiment',
        event_action: 'Untreated_HomePopularPizzaOneClick'
      }));
    }
  }, [oneClickDecisionEnabled, analytics, oneClickPopularPizzaTracking]);
}
