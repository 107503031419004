import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compact } from 'remeda';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { localizationSelectors } from '@/localization/localizationSelectors';
import hutFavorites from '@/graphql/queries/hutFavorites';
import SectionHeader from '@/homepage/sectionHeader';
import colors from '@/common/colors';
import fontStyles from '@/common/fontStyles';
import boxShadows from '@/common/boxShadows';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import {
  logArrayEmptyError,
  logGraphqlError,
  logMissingImageError,
  logMissingLinkError
} from '@/common/logger/logGraphqlError';
import { NATIONAL } from '@/localization/constants';
import { useVersionQuery } from '@/graphql/hooks/useVersionQuery';
import { updatePromoData } from '@/ReduxApplication/actions';
import { PromoType } from '@/dataAnalytics/dataAnalytics.d';
import { HomepageHutFavorite, HutFavorites as HutFavoritesResponse } from '@/homepage/HomepageTypes';
import HutFavoriteLegacyDeprecated from './HutFavoriteLegacyDeprecated';

const useStyles = makeStyles((theme: Theme) => createStyles({
  hutFavorites: {
    padding: '48px 0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '20px 0 32px'
    }
  },
  hutFavoritesContainer: {
    alignContent: 'flex-start',
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
  },
  hutFavorite: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: boxShadows.cardShadow,
    height: '110px'
  },
  iconButton: {
    padding: '6px'
  },
  hutPictureContainer: {
    height: 'inherit'
  },
  hutFavoriteImage: {
    height: '115px',
    width: 'auto'
  },
  hutFavoriteTextContent: {
    flexGrow: 1,
    marginLeft: '10px'
  },
  hutFavoriteTitle: {
    ...fontStyles.productTitle,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.productTitleMobile
    }
  },
  hutFavoriteSubTitle: {
    fontSize: '14px',
    color: colors.gray900
  },
  caratIcon: {
    color: colors.gray900
  }
}));

function getHutFavoritesData(data?: HutFavoritesResponse) {
  if (!data) {
    return {
      heading: '',
      link: {
        external: false,
        link: ''
      },
      firstFourFavorites: []
    };
  }
  const { heading } = data.Env.hutfavorites.meta;
  const link = data.Env.hutfavorites.meta.ctaLink;
  const { favorites } = data.Env.hutfavorites.products;
  const firstFourFavorites = compact<HomepageHutFavorite>(favorites.slice(0, 4));
  return {
    heading,
    link,
    firstFourFavorites
  };
}

const HutFavoritesListLegacyDeprecated = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, error, loading } = useVersionQuery<HutFavoritesResponse>({
    queryFn: hutFavorites,
    storeSpecific: false
  });
  const storeID = useSelector(localizationSelectors.storeIdEarly);
  const hutFavoritesData = data?.Env?.hutfavorites;
  const finishedLoadingButNoData = !loading && !data?.Env?.hutfavorites;

  const { heading, link, firstFourFavorites } = getHutFavoritesData(data);

  useEffect(() => {
    if (hutFavoritesData && !loading) {
      dispatch(updatePromoData(PromoType.HUT_FAVORITES_PROMO, hutFavoritesData));
      if (firstFourFavorites.length === 0) {
        logArrayEmptyError('Hut favorites', storeID);
      }
      logMissingImageError(firstFourFavorites, 'Hut favorites', storeID);
      logMissingLinkError(firstFourFavorites, 'Hut favorites', storeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (finishedLoadingButNoData || error) {
      logGraphqlError('Hut favorites', 'Query returned error', `store ID: ${NATIONAL}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoadingButNoData, error]);

  if (loading || error || finishedLoadingButNoData || firstFourFavorites.length === 0) {
    return null;
  }

  return (
    <Grid className={classes.hutFavorites}>
      <SectionHeader
        heading={heading}
        headerTestId="homepage-pop_pizza_header-text"
        linkTestId="homepage-pop_pizza_header_CTA-link"
        dataAnalyticsCategory="popular_pizzas"
        dataAnalyticsAction="Menu"
        {...link}
      />

      <Grid container className={classes.hutFavoritesContainer}>
        {firstFourFavorites.map((favorite, index) => (
          <HutFavoriteLegacyDeprecated key={index} index={index} favorite={favorite} classes={classes} />
        ))}
      </Grid>
    </Grid>
  );
};

export default HutFavoritesListLegacyDeprecated;
