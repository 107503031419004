import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import fontStyles from '@/common/fontStyles';
import AnchorLink, { AnchorLinkProps } from '@/common/AnchorLink/AnchorLink';
import { HomepageHutFavorite } from '@/homepage/HomepageTypes';

interface FavoriteLinkProps extends Omit<AnchorLinkProps, 'external' | 'link'> {
  favorite: HomepageHutFavorite;
  className?: string;
  tabIndex?: number;
}

const useStyles = makeStyles(() => createStyles({
  hutFavoriteLink: {
    ...fontStyles.commonComponentSelectedState,
    display: 'block',
    textDecoration: 'none',
    '&:focus': {
      padding: 0
    }
  }
}));

const FavoriteLink: React.FC<FavoriteLinkProps> = ({
  children, favorite, className, ...props
}) => {
  const classes = useStyles();
  return (
    <AnchorLink
      {...favorite.link}
      className={`${classes.hutFavoriteLink} ${className ?? ''}`}
      dataAnalyticsCategory="popular_pizzas"
      dataAnalyticsAction={favorite.title}
      {...props}
    >
      {children}
    </AnchorLink>
  );
};

export default FavoriteLink;
