import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { Product } from '@pizza-hut-us-development/client-core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Card, Grid } from '@material-ui/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';

import useGetSizeCrustDetails from '@/common/ProductDetails/hooks/useGetSizeCrustDetails';
import ProductId from '@/common/ProductId';
import { openLocalizationRail } from '@/localization/actions';

import { mobileStartBreakpoint } from '@/materialUi/theme';

import boxShadows from '@/common/boxShadows';
import fontStyles from '@/common/fontStyles';
import colors from '@/common/colors';
import Routes from '@/router/routes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onHutFavoriteClick } from '@/dataAnalytics/dataAnalyticsHelper';
import { AnalyticsDataModel } from '@/rootStateTypes';

export const PIZZA_MENU_TILE_LEGACY_TESTID = 'pizza-menu-tile-legacy';

const useStyles = makeStyles((theme: Theme) => createStyles({
  hutFavorite: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: boxShadows.cardShadow,
    height: '110px',
    cursor: 'pointer'
  },
  iconButton: {
    padding: '6px'
  },
  hutPictureContainer: {
    height: 'inherit'
  },
  hutFavoriteImage: {
    height: '115px',
    width: 'auto'
  },
  hutFavoriteTextContent: {
    flexGrow: 1,
    marginLeft: '10px'
  },
  hutFavoriteTitle: {
    ...fontStyles.productTitle,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.productTitleMobile
    }
  },
  hutFavoriteSubTitle: {
    fontSize: '14px',
    color: colors.gray900
  },
  caratIcon: {
    color: colors.gray900
  }
}));

const SingleHutFavorite = (props: any) => {
  const {
    favorite,
    isNationalStore,
    index
  } = props;

  const classes = useStyles();
  const router = useRouter();
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const sizeAndCrust = useGetSizeCrustDetails(true, favorite);
  const item = (dataModel: AnalyticsDataModel) => dataModel?.page?.items[index];

  const handleSelectHutFavorite = (hutFavorite: Product) => {
    if (isNationalStore) {
      dispatch(openLocalizationRail());
      return;
    }

    if (hutFavorite.outOfStock) return;

    const { globalId } = new ProductId(hutFavorite.id);
    router.push(`${Routes.MENU.PIZZA_BUILDER}?id=${globalId}`);

    const label = `product_${index + 1}`;
    analytics.push(() => onHutFavoriteClick(label, item(analytics.analyticsDataModel)));
  };

  return (
    <Grid data-testid={PIZZA_MENU_TILE_LEGACY_TESTID} key={index} item onClick={() => handleSelectHutFavorite(favorite)}>
      <Card className={classes.hutFavorite}>
        <ResponsiveImageRender
          mobile={favorite.imageURL}
          desktop={favorite.imageURL}
          altText=""
          title=""
          pictureClassName={classes.hutPictureContainer}
          className={classes.hutFavoriteImage}
          testId={`homepage-pop_pizza_${index}-image`}
        />
        <div aria-hidden className={classes.hutFavoriteTextContent}>
          <div className={classes.hutFavoriteTitle}>{favorite.name}</div>
          <div className={classes.hutFavoriteSubTitle}>{sizeAndCrust}</div>
        </div>
        <IconButton tabIndex={-1} className={classes.iconButton} disableRipple aria-label={`${favorite.subTitle}. ${favorite.tagLine}. Click to order.`}>
          <ArrowForwardIosIcon fontSize="small" className={classes.caratIcon} />
        </IconButton>
      </Card>
    </Grid>
  );
};

export default SingleHutFavorite;
