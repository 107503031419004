import React, { useEffect } from 'react';

import { useDecision } from '@optimizely/react-sdk';

import HutFavoritesListLegacyDeprecated from './components/HutFavoritesListLegacyDeprecated';
import HutFavoritesListLegacy from './components/HutFavoritesListLegacy';

const HutFavoritesListSectionLegacy = () => {
  const [{ enabled: renderFunctionalPopularPizzaSectionEnabled }] = useDecision('fr-dtg-915-render-functional-popular-pizza-section', { autoUpdate: true });
  const HutFavoritesComponent = renderFunctionalPopularPizzaSectionEnabled ? HutFavoritesListLegacy : HutFavoritesListLegacyDeprecated;

  return (
    <>
      <HutFavoritesComponent />
    </>
  );
};

export default HutFavoritesListSectionLegacy;
