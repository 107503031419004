import React from 'react';
import { Card, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ResponsiveImageRender from '../../../../../common/ResponsiveImageRender';
import { onHutFavoriteClick, onInitialHomePageScrollToHutFavorites } from '../../../../../dataAnalytics/dataAnalyticsHelper';
import useScrollTrackingForAnalytics from '../../../../../dataAnalytics/hooks/useScrollTrackingForAnalytics';
import telemetry from '../../../../../telemetry';
import { HomepageHutFavorite } from '../../../../HomepageTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { AnalyticsDataModel } from '@/rootStateTypes';
import HutFavoriteLink from '../HutFavoriteLink';

interface SingleHutFavoriteProps {
  index: number;
  favorite: HomepageHutFavorite;
  classes: { [key: string]: string };
}

const HutFavoriteLegacyDeprecated = ({ index, favorite, classes }: SingleHutFavoriteProps): JSX.Element => {
  const analytics = useAnalytics();

  const item = (dataModel: AnalyticsDataModel) => dataModel?.page?.items[index];
  const label = `product_${index + 1}`;
  const hutFavorites = analytics.analyticsDataModel?.page?.items;

  const loadingItem = analytics.analyticsDataModel?.page?.items[index];

  const ref = useScrollTrackingForAnalytics(
    onInitialHomePageScrollToHutFavorites,
    label,
    item,
    0.75,
    !loadingItem
  );

  const passOnClickAnalytics = () => {
    if (!hutFavorites) return;
    telemetry.addCustomEvent(`homepage-hut-favorite-${index + 1}-click`);
    analytics.push(() => onHutFavoriteClick(label, item(analytics.analyticsDataModel)));
  };

  return (
    <Grid key={index} item onClick={passOnClickAnalytics}>
      <HutFavoriteLink favorite={favorite} linkRef={ref} tabIndex={0} testId={`homepage-pop_pizza_${index}-link`}>
        <Card data-testid={`homepage-pop_pizza_${index}-card`} className={classes.hutFavorite}>
          <ResponsiveImageRender
            {...favorite.image}
            pictureClassName={classes.hutPictureContainer}
            className={classes.hutFavoriteImage}
            testId={`homepage-pop_pizza_${index}-image`}
          />
          <div aria-hidden className={classes.hutFavoriteTextContent}>
            <div className={classes.hutFavoriteTitle}>{favorite.title}</div>
            <div className={classes.hutFavoriteSubTitle}>{favorite.subTitle}</div>
            <div className={classes.hutFavoriteSubTitle}>{favorite.tagLine}</div>
          </div>
          <IconButton tabIndex={-1} className={classes.iconButton} disableRipple aria-label={`${favorite.subTitle}. ${favorite.tagLine}. Click to order.`}>
            <ArrowForwardIosIcon fontSize="small" className={classes.caratIcon} />
          </IconButton>
        </Card>
      </HutFavoriteLink>
    </Grid>
  );
};

export default HutFavoriteLegacyDeprecated;
