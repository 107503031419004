import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import HutFavoriteHeader from '@/homepage/HutFavoritesSection/components/HutFavoritesHeader';

import useGetHutFavorites from '@/homepage/HutFavoritesSection/hooks/useGetHutFavorites';

import { mobileStartBreakpoint } from '@/materialUi/theme';
import HutFavoriteLegacy from './HutFavoriteLegacy';

const useStyles = makeStyles((theme: Theme) => createStyles({
  hutFavorites: {
    padding: '48px 0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '20px 0 32px'
    }
  },
  hutFavoritesContainer: {
    alignContent: 'flex-start',
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
  }
}));

const HutFavoritesListLegacy = () => {
  const classes = useStyles();
  const { hutFavorites, isNationalStore } = useGetHutFavorites();

  return (
    <Grid data-testid="hut-favorites-list-legacy-container" className={classes.hutFavorites}>
      <HutFavoriteHeader />

      <Grid container className={classes.hutFavoritesContainer}>
        {hutFavorites.map((favorite, index) => (
          <HutFavoriteLegacy
            key={index}
            favorite={favorite}
            isNationalStore={isNationalStore}
            index={index}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default HutFavoritesListLegacy;
