import { Product } from '@pizza-hut-us-development/client-core';
import { ItemInputAnalytics } from '@/dataAnalytics/analyticsTypes';
import { onHutFavoriteOneClick } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import telemetry from '@/telemetry';

const useOneClickAnalytics = (isLoading: boolean) => {
  const analytics = useAnalytics();

  const onAddToCartClick = (index: number, favorite: Product) => {
    const item: ItemInputAnalytics = {
      itemIndex: index,
      itemList: 'Popular Pizza',
      itemPrice: favorite.price || 0,
      itemName: favorite.name,
      itemCategory: 'Popular Pizza',
      itemID: favorite.id,
      itemPosition: `${index}`,
      outOfStock: false
    };
    telemetry.addCustomEvent(`homepage-hut-favorite-${index + 1}-add-to-cart-click`);
    analytics.push(() => onHutFavoriteOneClick(`product_${index}`, item, true));
  };

  const onCustomizeLinkClick = (index: number, favorite: Product) => {
    const item: ItemInputAnalytics = {
      itemIndex: index,
      itemList: 'Popular Pizza',
      itemPrice: favorite.price || 0,
      itemName: favorite.name,
      itemCategory: 'Popular Pizza',
      itemID: favorite.id,
      itemPosition: `${index}`,
      outOfStock: false
    };

    telemetry.addCustomEvent(`homepage-hut-favorite-${index + 1}-customize-link-click`);
    analytics.push(() => onHutFavoriteOneClick(`product_${index}`, item, false));
  };

  return {
    onAddToCartClick,
    onCustomizeLinkClick
  };
};

export default useOneClickAnalytics;
